export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      // display: 'none',
      padding: '0rem'
    },
    '.logo': {
      // display: 'none',
      padding: '0rem',
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['70px', '90px', '110px', '130px']
        // filter: ['brightness(0)']
        // maxWidth: ['', '', 'unset', 'unset'],
      }
    },
    '.logoScrolled': {
      // display: 'none',
      padding: '0rem',
      img: {
        maxHeight: ['35px', '40px', '50px', '60px'],
        filter: 'brightness(0)',
        padding: '0rem'
      }
    },

    '.container': {
      padding: ['1.5rem', '3rem'],
      position: 'absolute',
      backgroundColor: 'transparent',
      background: 'linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0) 100%)',
      top: '0rem',
      '.smallNavMenu': {
        // margin: '0rem auto 0rem 2rem',
        '.navItem': {
          textShadow: '1px 1px 10px black',
          color: 'white',
          a: {
            fontSize: '1.25rem',
            fontFamily: 'heading',
            ':hover': {
              textDecoration: 'underline',
              color: 'lightgrey'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        },
        '.navItemDropdownMenu': {
          backgroundColor: 'transparent',
          width: 'fit-content'
        }
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.25rem 1rem',
      backgroundColor: 'white',
      // width: 'fit-content',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      borderBottom: '1px solid',
      borderColor: 'primary',
      height: 'auto',
      '.navItem': {
        a: {
          color: 'text',
          fontSize: '1.25rem',
          fontFamily: 'heading'
        }
      },
      '.smallNavMenu': {
        '.navItemDropdownMenu': {
          backgroundColor: 'white',
          width: 'fit-content'
        }
      }
    },
    '.hamburger': {
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', '', 'none'],
      '> div': {
        backgroundColor: 'primary'
      }
    },

    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    },
    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'light'
      }
    },
    '.navMenuLogo': {
      display: 'none'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '200vh'
    }
  },

  footer: {
    '.poweredByText': {
      filter: 'brightness(0) invert(1)',
      mb: '0.5rem'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['1.75rem', '2rem', '2.5rem', '2.75rem', '3rem'],
    fontWeight: 'bolder',
    letterSpacing: '4px',
    display: 'flex',
    color: 'primary',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    // textTransform: 'uppercase',
    fontFamily: 'heading'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    color: '#560f0f',
    fontFamily: 'subheading',
    fontWeight: '200'
  },
  text: {
    lineHeight: '1.5',
    fontSize: ['1rem', '', '1.1rem'],
    mb: '1.5rem',
    mt: '1rem',
    fontWeight: '600',
    '*': {
      color: 'inherit',
      fontWeight: 'inherit',
      lineHeight: '1.75',
      textAlign: 'inherit',
      fontSize: ['1rem', '', '1.1rem']
    }
  },

  sideBySide1: {
    height: ['', '', '85vh', '85vh'],
    backgroundColor: '#fdfcf6',
    padding: ['1rem', '', '0rem', '0rem'],

    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      justifyContent: 'flex-start',
      width: ['', '', '60%', '60%']
    },
    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['auto auto 2rem auto', '', 'auto'],
      width: ['', '', '40%', '40%'],
      height: ['', '', 'fit-content']
      // margin: ['', '', '', 'auto auto auto -6rem'],
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    'div.content': {}
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    backgroundColor: 'backgroundPrimary',
    height: ['', '', 'auto'],
    '.videoContainer': {
      height: 'auto'
    },
    marginBottom: [],
    '.content': {
      width: ['100%', '', '50%']
    },
    '.lazyload-wrapper': {
      width: ['100%', '', '50%']
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHeroShout: {
    position: ['', '', 'absolute'],
    bottom: '5vh',

    width: ['100%', '', '90%', '85%'],
    left: '50%',
    transform: ['', '', 'translateX(-50%)'],
    display: 'flex',
    flexDirection: 'row',
    maxWidth: ['', '', '', '900px'],
    borderRadius: '10px',
    overflow: 'hidden',
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    padding: ['1.5rem', '2rem', '0rem'],
    background: 'transparent',

    '.shoutWidget': {
      maxWidth: 'unset',
      background: 'transparent',
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      justifyContent: 'center',
      '.content': {
        display: 'flex',
        flexDirection: ['column', '', 'row'],
        justifyContent: 'center',
        alignItems: 'stretch',
        p: '0rem',
        width: ['100%', '', '65%']
      },
      '.imageContainer': {
        order: 2,
        width: ['100%', '', '20%'],
        maxWidth: ['', '', '200px']
      },
      '.title': {
        variant: 'customVariants.title',
        order: 1,
        display: 'none'
      },
      '.text': {
        variant: 'customVariants.text',
        order: 3,
        p: '1rem 2rem',
        color: 'white',
        opacity: '1',
        backgroundColor: '#312c2cde',
        fontSize: ['1.25rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem'],
        margin: '0rem',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      },
      '.date': {
        order: 1,
        color: 'white',
        backgroundColor: 'primary',
        fontSize: ['1.1rem', '1.1rem', '1.1rem', '1.1rem', '1.1rem', '1.1rem'],
        maxWidth: ['unset', '', '', '130px'],
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: '1rem'
      },
      '.shoutCTABtns ': {}
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageQuote1: {
    '.section': {
      '> div:nth-of-type(1)': {
        order: '2'
      },
      p: ['1.5rem'],
      display: 'flex',
      flexDirection: 'column',
      alignItems: ['flex-start', '', 'center']
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
      color: '#12ffdc'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: ['left', '', 'center']
    },
    '.linksContainer': {
      justifyContent: 'center'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2',
    '.content': {
      '.title': {
        order: '2',
        color: 'primary',
        fontFamily: 'display',
        fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
        border: 'none'
      },
      '.subtitle': {
        order: '1',
        fontSize: ['1.5rem', '1.6rem', '1.75rem', '2rem', '2.5rem']
      },

      '.text': {
        order: '3'
      },

      '.linksContainer': {
        order: '4'
      }
    }
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },
  homepageContact: {
    variant: 'customVariants.sideBySide2',
    mb: '0rem'
  },

  homepageShout: {
    backgroundColor: 'background',
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black']
    },
    '.text': {
      fontFamily: 'display',
      fontSize: ['1.7rem', '', '2.5rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'black'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['white', '', '', 'white'],
      color: ['text']
    },
    '.date': {
      display: 'none',
      backgroundColor: '#202020',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      order: ['', '', '4']
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  bio: {
    variant: 'customVariants.sideBySide1',
    margin: '0rem',
    padding: '2rem 1rem',
    'div.content': {
      width: ['', '', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      width: ['', '', '40%'],
      img: {
        objectFit: 'cover',
        objectPosition: 'top'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  menu: {
    '.allInContainerWrapper > .allInContainer > .menuContainer > .menuSectionTitle': {
      backgroundColor: 'black',
      color: 'white'
    },

    '.allInContainerWrapper > .allInContainer > .allInContainer > .menuContainer > .menuSectionTitle': {
      backgroundColor: 'primary',
      color: 'white',
      mb: '2rem'
    },
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontSize: ['1.5rem', '1.75rem', '2rem', '2.25rem', '2.25rem'],
      textAlign: 'left',
      mb: '1rem',
      border: 'none',
      color: 'primary'
    },

    '.menuSectionDescription': {
      textAlign: 'left',
      mb: '2.5rem',
      maxWidth: ['100%', '75%'],
      padding: '0rem 1rem',
      opacity: '0.6',
      fontWeight: 'bold',
      color: 'primary',
      fontSize: ['0.9rem', '1rem']
    },

    '.menuItemContainerImgActive': {
      height: '100%',
      mb: '0rem',
      '.menuItemInnerContainer': {
        flexDirection: ['column', '', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        '.menuItemContentContainer': {
          width: ['100%', '', '', '70%'],
          '.menuItemName': {
            variant: 'customVariants.title',
            fontSize: ['1rem', '1.1rem', '1.2rem', '1.3rem', '1.3rem'],
            mb: '0rem',
            color: 'text',
            letterSpacing: '0px',
            opacity: '0.8'
          },
          '.itemVariantsContainer': {
            display: 'flex',
            flexWrap: 'wrap'
          },
          '.variantsContainer': {
            display: 'flex',
            flexWrap: 'wrap',
            flexGrow: '1',
            margin: '0.5rem 1rem 0.5rem 0rem',
            // width: ['33.3%'],
            backgroundColor: 'primary'
          },
          '.variantContainer': {
            display: 'flex',
            width: '100%',
            fontFamily: 'body2',
            backgroundColor: 'primary',
            padding: '0.75rem',
            borderRadius: '10px'
          },
          '.menuItemPriceLabel': {
            fontWeight: 'bold',
            fontSize: '0.8rem',
            marginBottom: '0.25rem'
          },
          '.menuItemPrice': {
            color: 'text2'
          },
          '.variantContainerFloatTopRight': {
            fontSize: ['1.25rem', '1.5rem', '2rem']
          },
          '.menuItemLabelOptions': {
            fontSize: '1rem',
            maxWidth: '350px'
          },
          '.menuItemPriceVariants': {
            fontSize: '1rem',
            maxWidth: '350px',
            color: 'text2'
          },
          '.menuItemDescription': {
            width: '100%',
            fontFamily: 'body2',
            // opacity: '1',
            fontSize: '1rem',
            lineHeight: '1.5'
          }
        },

        '.menuItemImageContainer': {
          width: ['100%', '', '', '30%'],

          '.menuItemImg': {
            // objectPosition: 'top',
          }
        }
      }
    },
    '.menuItemPageContent': {
      '.itemVariantsContainer': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '.variantsContainer': {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '1',
        margin: '0.5rem 1rem 0.5rem 0rem'
        // width: ['33.3%'],
      },
      '.variantContainer': {
        display: 'flex',

        fontFamily: 'body2',

        padding: '0.75rem',
        borderRadius: '10px'
      },
      '.menuItemPriceLabel': {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        marginBottom: '0.25rem'
      },
      '.variantContainerFloatTopRight': {
        fontSize: ['1.25rem', '1.5rem', '2rem']
      },
      '.menuItemLabelOptions': {
        fontSize: '1rem',
        maxWidth: '350px'
      },
      '.menuItemPriceVariants': {
        fontSize: '1rem',
        maxWidth: '350px'
      },
      '.menuItemDescription': {
        width: '100%',
        backgroundColor: 'white',
        opacity: '1',
        lineHeight: '1.7',
        padding: '1rem 0.25rem'
      }
    },
    '.menuItemButton': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactPagehero: {
    '.content': {
      display: 'none'
    }
  },

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      backgroundColor: 'rgba(0,0,0,0.2)',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      color: 'white',
      width: 'auto',
      ':hover': {
        backgroundColor: 'black',
        color: 'white'
      }
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '1.75rem', '1.75rem', '1.75rem', '1.75rem'],
      alignItems: 'center',
      color: 'white'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'lightgrey'
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  cateringPage1: {
    '.secondaryCtaButton': {
      variant: 'buttons.primary'
    }
  }
}
